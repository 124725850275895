<template>
  <!-- 缴费明细 -->
  <div class="payment-details">
    <!-- 明细 -->
    <!-- <van-collapse v-model="activeNames">
      <van-collapse-item
        v-for="(item,index) in list"
        :key="item.rctp_no"
        :title="item.classify_name"
        :value="'￥'+ item.total_amt"
        :name="index"
      >
        <van-cell-group>
          <van-cell
            :title="item.classify_name"
            :value="item.amount + '                ' + '￥' + item.price"
          />
        </van-cell-group>
      </van-collapse-item>
    </van-collapse> -->

    <div
      class="details"
      v-for="item in list"
      :key="item.rctp_no"
    >
      <div class="details-card">
        <ul class="details-left">
          <li>类型</li>
          <li>数量</li>
          <li>自费</li>
          <li>总金额</li>
        </ul>
        <ul class="details-right">
          <li class="itemname">{{item.itemname}}</li>
          <li>{{item.quantity+item.unit}}</li>
          <li>{{'￥' + item.amt}}</li>
          <li>{{'￥' + item.amt}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { patientPayInfoDetail } from '@/network/service'

import { mapState } from 'vuex';
export default {
  name: 'paymentDetails',
  data () {
    return {
      //明细
      list: [],
      //当前激活
      activeNames: ['0'],
      //预缴费信息
      paymentInfo: {},
      //支付方式
      payway: {
        "0": ""
      }
    }
  },
  computed: {
    ...mapState(['seq', 'cardList'])
  },
  mounted () {
    console.log(this.seq)
    //明细
    this.patientPayInfoDetailPost()
  },
  methods: {
    //明细
    async patientPayInfoDetailPost () {
      let res = await patientPayInfoDetail({
        med_id: this.$store.state.med_id,
        card_no: this.$store.state.cardList[0].cardNo,
        card_type: this.$store.state.cardList[0].cardType,
        charge_id: this.$route.query.charge_id
      })
      if (res.code == '0') {
        this.paymentInfo = res.data.root.body
        this.list = res.data.root.body.detail
      } else {
        this.$toast(res.msg)
      }
    },

  }
}
</script>

<style lang="less" scoped>
/deep/.van-cell__value {
  color: #323233;
  white-space: pre;
}
.details {
  background-color: @cardColor;
  .details-card {
    display: flex;
    background-color: @cardColor;
    padding: 1rem;
    padding-bottom: 0;
    line-height: 2rem;
    margin: 1rem 0;
    .details-left {
      flex: 0.5;
    }
    .details-right {
      text-align: right;
      flex: 0.5;
      .itemname {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>